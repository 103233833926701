.App {
	text-align: center;
  }
  
  .App-logo {
	height: 40vmin;
	pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
	.App-logo {
	  animation: App-logo-spin infinite 20s linear;
	}
  }

  .cs-iconbox_title{
	font-size:24px !important;
  }

  .btn-daytime{
	background-color: #85aad2  !important;
	color:black;
	opacity:90%;
	margin-right:5px;
	height:30px;
  }
  
  .btn-daytime:hover{
	color:black;
	opacity:100%;
  }
  
  .btn-nighttime{
	background-color: #5665b6  !important;
	color:black;
	opacity:90%;
	margin-right:5px;
	height:30px;
  }
  
  .btn-nighttime:hover{
	color:black;
	opacity:100%;
  }
  

  .bg-soon {
	color:black;
	background-color:#e9ca7a !important;
  }

  .btn-secondary {
	color: white !important; 
  }

  .xmint-btn{
	margin: 0 auto !important;
	margin-top:10px !important;
	margin-bottom:10px !important;
	font-size:10px !important;
}

  button.xmint-btn span {
	display: none;
}
	button.xmint-btn:after {
	content: "Mint with Credit Card";
	color: white; 
	}


  .form-check-input:checked {
	background-color:#e9ca7a !important;
  }

  .form-check-input[type=checkbox]:indeterminate {
	background-color:#e9ca7a !important;
  }

  .form-check-input {
	background-color:#e9ca7a !important;
  }
  
  .btn-primary {
	color:black !important;
	background-color:#e9ca7a !important;
	border:none !important;
  }
  
  .btn-primary:hover {
	color:white !important;
	background-color:#e9ca7a !important;
	border:none !important;
  }
  
  
  .App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
  }
  .form-check-input{
	cursor:pointer !important;
  }
  
  .mobile-filter{
	background-color:#20223b !important;
  }
  
  .sticky {
	  position: -webkit-sticky;
	  position: sticky;
	  top: 0;
	  z-index: 1 !important;
  }
  
  .App-link {
	color: #61dafb;
  }
  
  @keyframes App-logo-spin {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }
  
  .disabled {
	color: black !important;
	opacity: .8;
  }

  .disabled-header {
	color: white !important;
	opacity: .8;
	pointer-events: none;
  }

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

  .disabled-events{
	pointer-events: none !important;
	opacity: .7;
  }
  



	.mint-module {
		background: rgba(23, 23, 28, 0.4);
		border: 4px solid black;
	}

	.form-select{
		color:white !important;
		opacity: 1 !important;
	}

	.form-select::placeholder {
		font-weight: bold !important;
		opacity: 1 !important;
		color: white !important;
	}
	
	
  
  .mint-image {
	justify-content: center;
	  align-items: center;
	height:125px !important;
	width:125px !important;
	padding:10px;
	margin-top:20px;
	background: black;
	border-radius: 50%;
  }
  
  
	.mint-text-small {
	  font-style: normal;
	font-weight: 400;
	font-size: 16px;
	text-decoration:none;
	color: #FFFFFF;
	}
  
  .mint-text-large {
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	text-decoration:none;
  color: #FFFFFF;
  }

  .cs-btn{
	font-size:14px !important;
  }
  
  .btn-war:hover{
	background-color: black !important;
  }
  
  .btn-day:hover{
	background-color: lightgreen !important;
  }
  
  .input-section {
	margin: 0 auto;
	padding:18px;
  }

  .none {
	display:  none !important;
  }

  .connect-section {
	margin: 0 auto;
  }

  .connect-section {
	text-align: center;
  }
  
  /* If the text has multiple lines, add the following: */
  .connect-section div {
	line-height: 1.5;
	display: inline-block;
	vertical-align: middle;
  }

  .text-loading {
	clip-path: inset(0 1.1ch 0 0);
	animation: text-load-animation 1s steps(4) infinite;
}
@keyframes text-load-animation {
	to {
		clip-path: inset(0 -.5ch 0 0)
}
}

@media (min-width:1200px)  { .fixedElement {
    position:fixed;
    top:0;
    z-index:100;
} }

.fixedElementRelative {
    position:relative;
}

.activebutton {
	background-color:green !important;
	pointer-events:none !important;
}